.section--about .section__header--decorative__decoration img {
  margin-bottom: units("8");
  padding-left: units("10");
  padding-right: units("10");

  @include media('>=tablet') {
    margin-bottom: 0;
    padding-left: units("2");
    padding-right: units("2");
  }

  @include media('>=desktop') {
    margin-bottom: 0;
    padding-left: units("6");
    padding-right: units("6");
  }
}

.section--competencies .content-grid__heading,
.section--vision .content-grid__item:first-child h4 {
    color: color("udp-mint");
    @include font-size('small');
    font-family: font("narrow");
    font-weight: weight("regular");
    text-transform: uppercase;
}

.section--vision .content-grid__item:first-child .content-grid__subgrid-item:nth-child(2) {
  @include media('>=phone-lg') {
    margin-top: units("7");
  }
}

.section--vision .content-grid__item:last-child .content-grid__subgrid-item:first-child {
  margin-top: units("3");
  flex: 0 0 100%;
  max-width: 100%;

  @include media('>=desktop') {
    flex: 0 0 25%;
  }
}

.section--vision .content-grid__item:last-child .content-grid__subgrid-item:last-child {

  @include media('>=phone-lg') {
    margin-top: units("7");
  }
}

.card .kicker {
  color: color("udp-green");
}

.project-hero .tag, .card .tag {
  background-color: color("udp-pine");
  color: color("udp-ivory");
}
.messages {
  color: color("gray-14");
  margin: 0;
  padding: 0;
  li {
      list-style: none;
      
  }
  .error {
      color: color("red-5");
  }
  .success {
      color: color("udp-green");
  }
}

.errorlist {
  float: right;
  li {
      list-style: none;
      color: color("red-5");
  }
  
  + p {
      label {
          color: color("red-5");
      }
      input {
          background: fade-out(color("red-5"), 0.75);
          border-color: color("red-5");
      }
  }
}