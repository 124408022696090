.header {
  padding: units($header-padding-y) 0;
}

.header__wrapper {
  align-items: center;
  display: flex;
  overflow: visible;
  justify-content: space-between;
  width: 100%;
}

.header__logo {
  display: block;
  height: $header-logo-height;
  width: $header-logo-width;
}

.header__logo-link,
.header__logo-link:visited {
  color: color($base-font-color);
  display: block;
  height: $header-logo-height;
  position: relative;
  width: $header-logo-width;
  
  svg {
    display: block;
    height: $header-logo-height;
    position: relative;
    width: $header-logo-width;
    z-index: 2;
  }
}

.header__logo-link:hover {
  color: color($base-font-color);
}

.header__logo-link:hover::after {
  background-color: color($highlight-color);
  bottom: -6px;
  content: "";
  height: units('1');
  position: absolute;
  width: 100%;
  z-index: 1;
}