.connect-page-body {
  @include quick-grid(320px);

  h1 {
    @include font-size("h2");
  }

  form {
    background-color: color("white");
    border-radius: radius("xs");
    box-shadow: 0 4px 16px rgba(0,0,0,.25);
    margin-top: units("4");
    padding: units("3");
  }
}

.connect-social-links {
  a,
  a:visited {
    color: color("white");
    margin-right: units("2");
    text-decoration: none;
  }
  svg {
    height: units("3");
    width: units("3");
  }
}

.name-fields {
  @include quick-grid(20rem, "0");
  margin-top: units("-2.5");
}