.people-grid {
  @include quick-grid();
  list-style: none;
  padding: 0;
}

.people-grid__link,
.people-grid__link:visited {
  color: unset;
  display: block;
  text-decoration: unset;
}

.people-grid__img {
  border-radius: radius("pill");
  height: units("15");
  margin-bottom: units("1");
  overflow: hidden;
  width: units("15");
}

.people-grid__name,
.people-grid__role {
  display: block;
  line-height: line-height("2");
}

.people-grid__role {
  @include font-size("small");
}

