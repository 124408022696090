.banner {
  background-color: color($banner-background-color);
  color: color($banner-text-color);
  padding: units($banner-padding-y) 0;
  text-align: $banner-text-align;
}

.banner__text {
  @include font-size($banner-font-size);
  margin-bottom: 0;
}