$content-grid-margin-y: '4';
//$section-child-margin-y;

.content-grid {
  @include grid-gap-row();
  display: flex;
  flex-wrap: wrap;
  margin-bottom: units("-#{$content-grid-margin-y}");
  margin-top: units("-#{$content-grid-margin-y}");

  .section .wrapper > *:not(.sr-only) + & {
    margin-top: calc(#{units(#{$section-child-margin-y})} - #{units(#{$content-grid-margin-y})});
  }
}


.content-grid__item {
  @include grid-gap-col();
  @include flexbox-col(12);
  line-height: line-height("3");
  margin-bottom: units($content-grid-margin-y);
  margin-top: units($content-grid-margin-y);

  @include media('>=tablet') {
    @include flexbox-col(6);
  }

  @include media('>=desktop') {
    @include flexbox-col(4);

    p,
    ul {
      @include font-size("small");
    }
  }

  ul {
    margin-top: 0;
    padding-left: units("3");

    li > ul {
      margin-top: 1em;
    }
  }

  li {
    padding-left: units("1")
  }

  p,
  li {
    margin-bottom: units("2.5");
  }
}

.content-grid__item--narrow {
  @include media('>=tablet') {
    @include flexbox-col(6);
  }

  @include media('>=desktop') {
    @include flexbox-col(3);
  }
}

.content-grid__item--wide {
  @include media('>=tablet') {
    @include flexbox-col(12);
  }

  @include media('>=desktop') {
    @include flexbox-col(6);
  }
}

.content-grid__item--full {
  @include media('>=tablet') {
    @include flexbox-col(12);
  }

  @include media('>=desktop') {
    @include flexbox-col(12);
  }
}

.content-grid__spot {
  display: block;
  margin-bottom: units('2');
}

.content-grid__heading,
.content-grid__body > h3,
.content-grid__body > h4 {
  @include font-size('h5');
  margin: 0 0 units('1');
}

.content-grid__body > img {
  height: auto;
}

// Sub grid
.content-grid__subgrid {
  @include font-size("small");
  @include grid-gap-row('1.5');
  display: flex;
  flex-wrap: wrap;  
}

.content-grid__subgrid-item {
  @include grid-gap-col('1.5');
  @include flexbox-col(12);

  @include media('>=phone-lg') {
    @include flexbox-col(6);
  }

  @include media('>=desktop') {
    @include equal-width-flexbox-col();
  }

  h4 {
    @include font-size("small");
    font-family: font("narrow");
    font-weight: weight($heading-weight-small);
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }
}