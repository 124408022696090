/// Quick grid mixin
/// 
/// @param {String} $minColWidth [gap] - Minimum column width
/// @param {String} $gridRowGap [gap] - Spacing unit for grid row gaps
/// @example scss 
///   .grid-col {
///       @include quick-grid();
///   }
///
/// @example scss 
///   .grid-col {
///       @include quick-grid(200px);
///   }
@mixin quick-grid(
  $minColWidth: 15rem, 
  $gridRowGap: $grid-column-gap
  ) 
  {
  $minColWidth: if($minColWidth == null, 15rem, $minColWidth);
  $gridRowGap: if($gridRowGap == null, $grid-column-gap, $gridRowGap);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($minColWidth, 1fr));
  grid-column-gap: units($grid-column-gap);
  grid-row-gap: units($gridRowGap);
}