.wrapper {
  margin: 0 auto;
  max-width: $site-max-width;
  overflow: hidden;
  padding: 0 units($site-wrap-padding-sm);

  @include media('>=tablet') {
    padding: 0 units($site-wrap-padding-lg);
  }
}

.main-content[tabindex]:focus {
  outline: 0;
}