/// Unstyled list helper
/// @example scss 
///   .foo {
///       @include unstyled-list;
///   }
@mixin unstyled-list {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  > li {
    margin-bottom: 0;
    max-width: unset;
  }
}