$section-child-margin-y: "10";

.section {
  padding-bottom: units("15");
  padding-top: units("15");

  // Consistent spacing after the first child
  .wrapper > *:not(.sr-only) + * {
    margin-top: units($section-child-margin-y);
  }

  &:focus {
    outline: none;
  }
}

.section--thin {
  padding-bottom: units("9");
  padding-top: units("9");
}

.section--blue-5 {
  background-color: color("blue-5");
}

.section--blue-7 {
  background-color: color("blue-7");
}

.section--blue-5,
.section--blue-7 {
  @include heading-colors("white");
  color: color("white");

  .lead-text {
    color: color("white");
  }

  a {
    color: color("white");
  }

  li::marker {
    color: color("white");
  }
}

.section--gray-14 {
  @include heading-colors("white");
  background-color: color("gray-14");
  color: color("white");
}

.section--gray-1 {
  background-color: color("gray-1");
}

// Section headers
.section__heading {
  font-weight: weight($section-heading-weight);
  margin-top: 0;
  max-width: measure("0.5");
}

.section__description {
  margin-bottom: 0;
  max-width: measure("5");

  .section--blue-5 &,
  .section--blue-7 &,
  .section--gray-14 & {
    color: color("white");
  } 
}

.section__header--centered {
  text-align: center;

  .section__heading,
  .section__description {
    margin-left: auto;
    margin-right: auto;
  }
}

// Calls to action 
.section .actions-block {
  margin-top: units("4");
  padding-bottom: units('1');

  .button {
    margin-bottom: units("3");
    margin-right: units("1");
  }
}

// Section headers with offsets
.section__header--offset {

  @include media('>=tablet') {
    @include column-offset(4);
    @include flexbox-col(8);
    @include grid-gap-col();
    padding-right: 0;
  }

  @include media('>=desktop') {
    @include flexbox-col(6);
    @include column-offset(6);
  }
}

// Section headers with illustrations

.section__header--decorative {
  @include grid-gap-row();
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .section__header--decorative__decoration {
    @include flexbox-col(12);
    @include grid-gap-col();
    text-align: center;

    @include media('>=tablet') {
      @include flexbox-col(5);
    }
    @include media('>=desktop') {
      @include flexbox-col(6);
    }

    svg {
      height: auto;
      max-width: 100%;
    }
  }

  .section__header--decorative__content {
    @include flexbox-col(12);
    @include grid-gap-col();

    @include media('>=tablet') {
      @include flexbox-col(7);
    }
    @include media('>=desktop') {
      @include flexbox-col(6);
    }
  }
}