///
/// Sets width of container based on 12-column grid
///
/// @param {Number} $size - Number 1 -- 12
/// @group Layout
///
/// @example scss 
///   .grid-col {
///       @include flexbox-col(6);
///   }
@mixin flexbox-col($size) {
  flex: 0 0 percentage($size / $grid-columns);
  max-width: percentage($size / $grid-columns); // IE10+ and Firefox
}

///
/// Makes all columns in grid equal width
/// @group Layout
///
/// @example scss 
///   .grid-col {
///       @include equal-width-flexbox-col;
///   }
@mixin equal-width-flexbox-col() {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

///
/// Shifts columns to right along the design grid
/// @group Layout
///
/// @example scss 
///   .grid-col {
///       @include column-offset(3);
///   }
@mixin column-offset($size) {
  margin-left: percentage($size / $grid-columns);
}