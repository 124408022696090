.icon {
  color: color("blue-5");
  height: units("6");
  max-width: units("6");
  width: 100%;
}

.icon--md {
  height: units("8");
  max-width: units("8");
}

.icon--lg {
  height: units("20");
  max-width: units("20");
}