.flippy-grid__row {
  @include grid-gap-row();
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: units("8");

  &:last-child {
    margin-bottom: 0;
  }

  @include media(">=tablet") {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.flippy-grid__row:nth-child(even) {
  @include media(">=tablet") {
    flex-direction: row-reverse;
  }
}

.flippy-grid__image,
.flippy-grid__description {
  @include grid-gap-col();
  margin-bottom: units("1");
}

.flippy-grid__image {

  @include media(">=tablet") {
    @include flexbox-col(6);
  }
  @include media(">=desktop") {
    @include flexbox-col(5);
  }

  img {
    @include img-border;
    margin-bottom: units("1");
  }

  p {
    @include font-size("small");
    margin-bottom: 0;
    text-align: center;
  }
}

.flippy-grid__description {

  @include media(">=tablet") {
    @include flexbox-col(6);
  }
}

.flippy-grid__heading {
  @include font-size('h5');
  margin-top: 0;
}

// Flippy grids with big images
.flippy-grid--big-image {
  margin-bottom: units("20");

  .flippy-grid__image {
    @include grid-gap-col("6");
    
    @include media(">=tablet") {
      @include flexbox-col(7);
    }
    @include media(">=desktop") {
      @include flexbox-col(8);
    }
  } 
  .flippy-grid__description {
    @include grid-gap-col("6");

    @include media(">=tablet") {
      @include flexbox-col(5);
    }
    @include media(">=desktop") {
      @include flexbox-col(4);
    }
  }
}