button,
.button {
  &:not([disabled]) {
    &:focus {
      @include focus-outline(null, null, null, "0.25");
    }
  }
}

iframe,
[href],
[tabindex],
[contentEditable="true"] {
  &:focus {
    @include focus-outline;
  }
}