fieldset {
  border: 0;
  padding: 0;
  margin-bottom: units("4");
}

legend {
  @include font-size($legend-font-size);
  color: color($legend-font-color);
  font-family: font($heading-font);
  font-weight: weight($legend-font-weight);
}

input,
textarea {
  border: $field-border-width color($field-border-color) solid;
  padding: units($field-padding-y) units($field-padding-x);
  width: 100%;
}

label {
  @include font-size($field-label-font-size);
  color: color($field-label-color);
  display: block;
  font-weight: weight($label-font-weight);
  margin-top: 2em;
}