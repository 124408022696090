.overlay {
  background-color: rgba(color("gray-14"), .8);
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: opacity duration('1') ease-in-out;
  width: 100vw;
  z-index: z-index("overlay");

  &.is-ready {
    display: block;
    opacity: 0;
  }

  &.is-visible {
    opacity: 1;
  }

  .nav-open & {
    @include media(">=nav-width") {
      display: none;
    }
  }
}