$certs-margin: "3";

.certifications {
  @include grid-gap-row();
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: units("-#{$certs-margin}");
  margin-top: units("-#{$certs-margin}");
  padding: 0;
}

.certifications__item {
  @include flexbox-col(6);
  @include font-size("small");
  @include grid-gap-col();
  margin: units($certs-margin) 0;
  text-align: center;

  @include media(">=tablet") {
    @include flexbox-col(3);
  }
}

.certifications__logo {
  width: 100%;
  margin: 0 auto units('1');
  padding: 0 30%;

  @include media(">=tablet") {
    padding: 0 20%;
  }
  @include media(">=desktop") {
    padding: 0 30%;
  }
}