// Standard card list
.card-list {
  @include grid-gap-row();
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  .card-wrapper {
    display: inline-block; // Jared: I'm not sure about this
  }
  + .card-list {
    margin-top: 0 !important;
  }
}

.card,
.card-wrapper {
  @include grid-gap-col();
  @include flexbox-col(12);

  @include media(">=tablet") {
    @include flexbox-col(6);
  }
}

.card-list--col-3 .card-wrapper {
  @include media(">=tablet") {
    @include flexbox-col(4);
  }
}

.card-list--col-4 .card-wrapper {
  @include media(">=tablet") {
    @include flexbox-col(6);
  }

  @include media(">=desktop") {
    @include flexbox-col(3);
  }
}

.card {
  display: flex;
  flex-direction: column;

  a {
    color: color($base-font-color);
    display: block;
    text-decoration: none;

    .section--blue-5 &,
    .section--blue-7 &,
    .section--gray-14 & {
      color: color("white");
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: color($base-font-color);
    }
  }
}

// Standard cards use a full wrapper so the whole thing is clickable
.card .card__wrapper-link {
  background-color: color("white");
  border: 1px solid color("gray-1");
  color: color($base-font-color);
  display: block;
  height: 100%;
  margin-bottom: units("7");

  .card__heading {
    color: color($base-font-color);
  }

  .card__content {
    color: color($base-font-color);
    padding: units('2');
    height: auto;
  }

  &:hover {
    text-decoration: none;

    .card__heading {
      text-decoration: underline;
    }
  }
}

.card__artwork {
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  .tag {
    position: absolute;
    left: units("1");
    top: units("1");
  }
}

.card__heading {
  @include font-size("h5");
  margin: 0;
}

.card__heading--small {
  @include font-size("h6");
  margin: 0;
}

.card__heading--extra-space {
  margin: units("2") 0;
}

.card__description {
  line-height: line-height("3");

  @include media(">=tablet") {
    @include font-size("small");
  }
}

.card__content {

  @include media(">=tablet") {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.card__read-more,
.card__read-more .button {

  @include media(">=tablet") {
    @include font-size("small");
    margin-top: auto;
  }
}

// Card list displayed as column
.card-list--column {
  flex-direction: column;
  flex-wrap: nowrap;

  .card {

    @include media(">=tablet") {
      @include flexbox-col(12);
    }
  }
}

// Cards with a horizontal layout
.card--horizontal {
  @include media(">=tablet") {
    @include grid-gap-row();
    align-items: center;
    flex-direction: row;
    justify-content: center;;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .card__artwork {
    @include media(">=tablet") {
      @include flexbox-col(4);
      @include grid-gap-col();
    }
    @include media(">=desktop") {
      @include flexbox-col(3);
    }

    .tag {
      @include media(">=tablet") {
        left: units("2");
      }
    }
  }

  .card__content {
    padding-top: units("2");

    @include media(">=tablet") {
      @include flexbox-col(6);
      @include grid-gap-col();
      padding-top: 0;
    }
  }

  .card__heading {
    @include font-size("h6");
  }
}

// Cards with a horizontal layout inside regular card set
.card-wrapper {
  margin-bottom: units("7");

  .card {
    height: 100%;
    padding-left: 0;
    padding-right: 0;

    @include media(">=tablet") {
      @include flexbox-col(12);
      margin-bottom: 0;
    }
  }
  .card--horizontal {
    @include grid-gap-row('1');
    @include flexbox-col(12);
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;

    .card__artwork {
      @include media(">=tablet") {
        @include flexbox-col(6);
        @include grid-gap-col('1');  margin-bottom: units("7");

  .card {
    height: 100%;
    padding-left: 0;
    padding-right: 0;

    @include media(">=tablet") {
      @include flexbox-col(12);
      margin-bottom: 0;
    }
  }
      }
      @include media(">=desktop") {
        @include flexbox-col(6);
      }
    }
  
    .card__content {
      @include media(">=tablet") {
        @include equal-width-flexbox-col();
        @include grid-gap-col('1');
      }
    }
  }
}

// Card lists in a grd
.card-list-wrapper {
  @include grid-gap-row();
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  .card-list {
    @include flexbox-col(12);
    margin: 0;
    padding: 0;

    @include media(">=tablet") {
      @include flexbox-col(6);
    }

    .card,
    .card-wrapper {
      @include flexbox-col(12);
    }
  }
}