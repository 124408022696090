// Taglines
.kicker {
  @include font-size("small");
  font-weight: weight("regular");
  line-height: line-height("1");
  margin-bottom: .25em;
  text-transform: uppercase;
}

// Separators
.separator {
  background-color: currentColor;
  border-radius: radius('sm');
  display: inline-block;
  height: .25em;
  margin: 0 .75em;
  width: .25em;
}

// Lead text
.lead-text {
  @include font-size("h5");
  color: color($lead-text-color);
}

// Details callout
.details-callout {
  @include font-size("small");
  line-height: line-height("2");
}

.details-callout__term {
  color: color($base-font-color);
  font-weight: weight("regular");
  text-transform: uppercase;
}

.details-callout__description {
  color: color($base-font-color-light);
}

.details-callout__description + .details-callout__term {
  margin-top: 1em;
}