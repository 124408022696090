$content-grid-margin-y: '4';

.quote-grid {
    @include grid-gap-row();
    display: flex;
    flex-wrap: wrap;
    margin-bottom: units('-#{$content-grid-margin-y}');
    margin-top: units('-#{$content-grid-margin-y}');
  
    .section .wrapper > *:not(.sr-only) + & {
      margin-top: calc(#{units(#{$section-child-margin-y})} - #{units(#{$content-grid-margin-y})});
    }
  }

  .quote-grid__item {
    @include grid-gap-col();
    @include flexbox-col(12);
    line-height: line-height('3');
    margin-bottom: units($content-grid-margin-y);
    margin-top: units($content-grid-margin-y);
  
    @include media('>=tablet') {
        @include flexbox-col(6);
    }

    &:nth-child(3n - 2) {   
        @include media('>=tablet') {
            @include flexbox-col(12);
        }
    }
  
    blockquote {
        background-image: image('/svg/brand/quotes.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        padding-top: units('6');
        margin-bottom: units('2.5');
    }
  }

  .quote-grid__attribute {
    @include font-size("small");

    .quote-grid__attribute-name {
        display: block;
        text-transform: uppercase;
    }
  }