.sr-only {
  @include sr-only;
}

.skipnav {
  @include font-size($skipnav-font-size);
  background: transparent;
  color: color($skipnav-font-color);
  left: 0;
  padding: units('1') units('2');
  position: absolute;
  top: -3.8rem; // skipnav link height
  transition: all duration("1") ease-in-out;
  z-index: 100;

  &:focus {
    background: color($skipnav-background-color);
    left: 0;
    position: absolute;
    transition: all duration("3") ease-in-out;
    top: 0;
  }
}