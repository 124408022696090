.post-content {
  display: grid;
  grid-column-gap: units($grid-column-gap);
  grid-template-rows: auto;
  grid-template-columns:
    minmax(units("2.5"), 2fr)
    minmax(units("2.5"), 1fr)
    minmax(auto, 57ch)
    minmax(units("2.5"), 1fr)
    minmax(units("2.5"), 2fr);
    
  > h2,
  > h3,
  > h4,
  > h5,
  > p,
  > ul,
  > a,
  > div {
    grid-column: 1 / 6;

    @include media(">=tablet") {
      grid-column: 2 / 5;
    }

    @include media(">=desktop") {
      grid-column: 3 / 3;
    }
  }

  > h2 {
    @include font-size("h3");
    margin-bottom: units("3");
  } 

  .details-callout {
    grid-column: 1 / 6;
    margin: units("5") 0;

    @include media(">=tablet") {
      grid-column: 2 / 5;
    }

    @include media(">=desktop") {
      grid-row: 2 / 5;
      grid-column: 1 / 3;
      margin: 0;
    }
  }

  > img {
    @include img-border;
    grid-column: 1 / 6;
    height: auto;
    margin-bottom: 1.804rem;
    
    @include media(">=desktop") {
      grid-column: 2 / 5;
    }
  }

  .actions-block .button {
    margin: units("2") units("2") units("2") 0;
  }
}