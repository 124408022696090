
.main-search {
    flex: 1 1 auto;
    position: relative;

   .button {
        border: 0;
        bottom: 1px;
        color: color('blue-3');
        cursor: pointer;
        height: auto;
        max-width: calc(4.8rem - 1px);
        padding: 10px;
        position: absolute;
        right: 1px;
        top: 1px;
        
        &:hover {
            background: color('blue-3');
            color: white;
        }

        svg {
            height: 100%;
            width: 100%;
        }
    }
}

.input-search {
    &:focus, &:active {
        + .button {
            bottom: 2px;
            max-width: calc(4.8rem - 2px);
            right: 2px;
            top: 2px;
        }
    }
}

.section--search-results--header {
    h1 {
        @include font-size('h3');
    }
}
