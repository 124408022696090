// Pull Quotes
.pullquote {
  padding: units("1") 0;

  @include media('>=tablet') {
    @include grid-gap-row();
    align-items: center;
    display: flex;
  }
}

// Headshot section
.pullquote__headshot {
  position: relative; 

  @include media('>=tablet') {
    @include flexbox-col(4);
    @include grid-gap-col();
  }
}

.pullquote__headshot__img {
  @include circle-image;
  display: block;
  width: 100%;
}

.pullquote__headshot__company {
  @include circle-image;
  bottom: 7%;
  display: block;
  height: units('10');
  position: absolute;
  right: 7%;
  width: units('10');
}

// Quote icon
.pullquote__icon {
  height: 100px;
  width: 100px;
  @include media('>=tablet') {
    left: -80px;
    position: absolute;
    top: -26px;
  }
}

// Quote section
.pullquote__quote {
  position: relative;
  
  @include media('>=tablet') {
    @include grid-gap-col();
    @include flexbox-col(7);
    margin-left: auto;
  }
}

.pullquote__text {
  @include font-size('h4');
  line-height: line-height('2');
  max-width: measure('2');
}

.pullquote__source {
  line-height: line-height('3');
  margin-top: units("4");
}

.pullquote__source__name,
.pullquote__source__rol,
.pullquote__source__org,
.pullquote__source__company-logo {
  display: block;
}

.pullquote__source__name {
  font-weight: weight("regular");
  text-transform: uppercase;
}

.pullquote__source__company-logo {
  @include circle-image;
  height: units('6');
  margin-bottom: units('4');
  width: units('6');
}

// Pullquote without a headshot
.pullquote--no-headshot {

  .pullquote__quote {
    position: static;
  
    @include media('>=tablet') {
      @include flexbox-col(12);
      margin: 0 auto;
      text-align: center;
    }
  }

  .pullquote__icon {
    height: units("6");
    width: units("6");

    @include media('>=tablet') {
      position: static;
    }
  }

  .pullquote__text {
    margin-left: auto;
    margin-right: auto;
  }

  .pullquote__source__company-logo {
    margin-left: auto;
    margin-right: auto;
  }
}