.footer {
  background-color: color($footer-background-color);
  color: color($footer-text-color);
  overflow: hidden;
  padding: units($footer-padding-y) 0;
  
  a,
  a:visited {
    color: color($footer-link-color);
  }

  a:hover {
    text-decoration: $footer-link-decoration-line-hover;
  }

  .wrapper {
    overflow: visible;
  }
}

.footer__main {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media(">=tablet") {
    align-items: flex-start;
    flex-wrap: nowrap;
  }
}

.footer__brand {
  align-items: center;
  display: flex;

  @include media(">=tablet") {
    @include flexbox-col(6);
  }

  @include media(">=desktop") {
    flex-basis: auto;
    flex-grow: 0;
    margin-right: units('3');
  }
}

.footer__brand__logo {
  color: color($footer-text-color);
  height: $footer-logo-height;
  width: $footer-logo-width;
}

.footer__nav {
  width: 100%;

  @include media(">=tablet") {
    @include flexbox-col(6);
  }

  @include media(">=desktop") {
    flex-basis: auto;
    flex-grow: 0;
    max-width: none;
    width: auto;
  }
}

.footer__nav-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 units('-2');
  padding: 0;
}

.footer__nav-item {
  @include flexbox-col(12);
  margin: 0 units('2');
  padding: units('2') 0;

  @include media(">=tablet") {
    flex-basis: auto;
    flex-grow: 0;
    padding: 0 0 units('2') 0;
  }
}

.footer__nav-item--row {
  flex-basis: auto;
  flex-grow: 0;
}

.footer__nav-link {
  display: block;
}

.footer__nav-link__icon {
  color: color($footer-link-color);
  display: block;
  height: units('4');
  padding: units('0.5');
  width: units('4');
}

.footer__mailing-address {
  line-height: line-height('2');
  margin: units('2.5') 0;
}

.footer__copyright {
  @include font-size('small');
  display: inline-block;
  margin-right: units('3');

  a,
  span {
    display: inline-block;
    margin-right: units('2');
  }
}