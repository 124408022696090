.internal-nav {
  background-color: color("white");
  border-bottom: 1px solid color("gray-2");
  border-top: 1px solid color("gray-2");
  display: none;
  font-family: font("wide");
  padding: 0;
  z-index: z-index("sticky-nav");

  @include media(">=desktop") {
    display: block;
  }
}

.internal-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.internal-nav__list-item {
  margin: 0 units("2");
}

.internal-nav__link,
.internal-nav__link:visited {
  color: color("gray-14");
  display: block;
  padding: units("2");
  text-decoration: none;

  &.current {
    color: color("gray-14");
    border-bottom: 4px solid color($internal-nav-active-color);
    font-weight: weight("regular");
  }
}

.internal-nav__link:hover {
  color: color("gray-7");
  text-decoration: none;

  &.current {
    color: color("gray-14");
    text-decoration: none;
  }
}

// Remove outline
.internal-nav + div[id][tabindex]:focus {
  outline: 0;
}