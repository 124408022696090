/// Colors all headings
/// @example scss 
///   .foo {
///       @include heading-colors($base-font-color);
///   }
@mixin heading-colors($color) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: color($color);
  }
}