.nav {
  backface-visibility: hidden;
  background-color: color("white");
  bottom: 0;
  display: none;
  overflow-y: scroll;
  padding: units("2") units("6");
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100vw);
  transition: transform duration('1') ease-in-out;
  width: 100vw;
  z-index: z-index('flyout-menu');
  -webkit-overflow-scrolling: touch;
  

  @include media('>=tablet-sm') {
    transform: translateX(50vw);
    width: 50vw;
  }

  @include media('>=nav-width') {
    background-color: color("transparent");
    display: block;
    overflow: visible;
    padding: 0;
    position: static;
    transform: translateX(0);
    width: auto;
  }

  &.is-ready {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  &.is-visible {
    transform: translateX(0);
    transition: transform duration('3') ease-in-out;
  }
}

.nav__button {
  align-items: center;
  appearance: none;
  background-color: color('transparent');
  border: 0;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: units('5');
  justify-content: center;
  width: units('5');

  @include media('>=nav-width') {
    display: none;
  }

  svg {
    display: block;
    flex-shrink: 0;
    height: units('3');
    margin: 0 auto;
    width: units('3');
  }
}

.nav__button--close {
  margin-left: auto;
  margin-right: units("-2");
}

.nav__list {
  @include unstyled-list;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  margin-top: units("3");
  padding-bottom: units("2");

  @include media('>=nav-width') {
    flex-direction: row;
    margin-right: calc(0rem - #{units($navigation-margin-x)} - #{units($navigation-padding-x)});
    margin-top: 0;
    padding-bottom: 0;
  }
}

.nav__item {
  margin-bottom: units("2");
  margin-top: units("2");
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @include media('>=tablet-sm') {
    margin-bottom: units("3");
    margin-top: units("3");
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media('>=nav-width') {
    margin: 0 units($navigation-margin-x);
    width: auto;
  }

  .button {
    width: 100%;
  }
}

.nav__item--home {
  
  @include media('>=nav-width') {
    display: none;
  }
}

.nav__item--social-icon-desktop {
  display: none;

  @include media(">=nav-width") {
    display: inline-block;
    align-self: center;
  }
}

.nav__link,
.nav__link:visited {
  align-items: center;
  border-bottom: 3px transparent solid;
  color: color($navigation-link-color);
  display: flex;
  font-weight: weight($navigation-link-weight);
  text-decoration: none;
  width: 100%;

  @include media('>=nav-width') {
    padding: units($navigation-padding-y) units($navigation-padding-x);
  }

  svg {
    display: block;
    height: 1em;
    margin: 0 .5em;
    width: 1em;
  }
}

.nav__link:hover,
.nav__link--current,
.nav__link--current:visited {
  border-bottom-color: color($highlight-color);
  color: color($navigation-link-color);
  text-decoration: none;
}

.nav__link--current:hover,
.nav__link--current:visited:hover {
  border-bottom-color: transparent;
  text-decoration: none;
}

.nav__link--current,
.nav__link--current:visited {
  @include media("<nav-width") {
    border-bottom: none;
    font-weight: weight("bold");
  }
}

.nav__tagline {
  margin: 0;
  text-align: center;

  @include media(">=nav-width") {
    display: none;
  }
}

.nav__social {
  display: flex;
  justify-content: center;;
  list-style: none;
  margin: units("4") 0;
  padding: 0;

  @include media(">=nav-width") {
    display: none;
  }
}

.nav__social__item {
  margin: 0 units("2");
}

.nav__social__link,
.nav__social__link:visited {
  color: color($base-font-color);
  display: block;
  
  svg {
    display: block;
    height: units("5");
    width: units("5");
  }
}

.nav__social__link__desktop,
.nav__social__link__desktop:visited {
  color: color($base-font-color);
  display: block;
  
  svg {
    display: block;
    height: units("3");
    width: units("3");
  }
}