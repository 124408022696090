.section--gray-14 {
  a:not(.button),
  a:not(.button):visited {
    color: color("udp-mint");
  }
}

.section--about {
  background-image: image('udp-about-bg.svg');
  background-position: center center;
  background-repeat: none;
  background-size: cover;
}

.section--differentiators {
  background-image: image('udp-differentiators-bg.svg');
  background-position: center center;
  background-repeat: none;
  background-size: cover;
}

.section--competencies {
  background-image: image('udp-competencies-bg.svg');
  background-position: center center;
  background-repeat: none;
  background-size: cover;
}

.section--vision {
  background-color: color("udp-ink");
}

.section--connect {
  background-color: color("udp-ink");
}
