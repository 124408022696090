.hero {
  background: color($hero-background-color);
  padding: units("10") 0;

  @include media('>=desktop') {
    padding: units("15") 0;
  }

  .actions-block {
    margin-top: units("3");
  }
}

.hero__wrapper--inner {
  @include grid-gap-row();
  display: flex;
  justify-content: center;
}

.hero__content {
  @include grid-gap-col();
  margin: 0 units("2");
  padding: units("3");
  position: relative;

  @include media('>=tablet') {
    @include flexbox-col(10);
    margin: 0 auto;
    padding: units("5");
  }

  @include media('>=desktop') {
    @include flexbox-col(8);
    padding: units("7");
  }
}

.hero__kicker {
  @include font-size($hero-kicker-font-size);
  color: color($hero-kicker-color);
  font-weight: weight($heading-weight-small);
  letter-spacing: 1.5px;
  margin: 0;
  text-transform: uppercase;
}

.hero__heading {
  color: color($hero-heading-font-color);
  @include font-size($hero-heading-font-size);
}

.hero__lead-text {
  @include font-size($hero-lead-text-font-size);
  color: color($hero-lead-text-font-color);
  line-height: line-height($hero-lead-text-line-height);
}

.hero__frame {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%; 
}

.hero__corner {
  height: units("3");
  position: absolute;
  width: units("3");

  @include media('>=tablet') {
    height: units("5");
    width: units("5");
  }

  @include media('>=tablet') {
    height: units("7");
    width: units("7");
  }
}

.hero__corner--top-left {
  left: 0;
  top: 0;
}

.hero__corner--top-right {
  right: 0;
  top: 0;
  transform: scaleX(-1);
}

.hero__corner--bottom-left {
  bottom: 0;
  left: 0;
  transform: scaleY(-1);
}

.hero__corner--bottom-right { 
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}


// Heroes for Project pages

.project-hero {
  background-repeat: no-repeat;
  background-size: cover;
  padding: units("2") 0;
  position: relative;

  &:after {
    background-color: color($project-hero-overlay-color);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: .9;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .wrapper {
    display: grid;
    grid-column-gap: units($grid-column-gap);
    grid-template-rows: auto;
    grid-template-columns:
      minmax(units("2.5"), 2fr)
      minmax(units("2.5"), 1fr)
      minmax(auto, 57ch)
      minmax(units("2.5"), 1fr)
      minmax(units("2.5"), 2fr);
    }
}

.project-hero__content {
  grid-column: 1 / 6;
  padding: units("6") 0;
  position: relative;
  z-index: 10;

  @include media(">=tablet") {
    grid-column: 2 / 5;
  }

  @include media(">=desktop") {
  }
}

.project-hero__heading {
  @include font-size("h4");
  @include media(">phone-lg") {
    @include font-size("h3");
  }
  @include media(">=tablet") {
    @include font-size("h2");
  }
  @include media(">=desktop") {
    @include font-size("h1");
  }
}

.project-hero__heading,
.project-hero__lead-text {
  color: color("white");
}


.project-hero__lead-text {
  @include font-size("h5");
}

.project-hero__image-desc {
  @include font-size("small");
  color: color("white");
  grid-column: 1 / 6;
  text-align: center;
  position: relative;
  z-index: 10;
}