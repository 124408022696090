html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;

  &.nav-open {
    max-height: 100vh;
    overflow: hidden;

    @include media('>=nav-width') {
      overflow-y: visible;
    }
  }
}

body {
  @include font-size('base');
  background-color: color($body-background-color);
  color: color($base-font-color-light);
  font-family: font($body-font);
  font-weight: weight($body-font-weight);
  line-height: line-height($base-line-height);
  margin: 0;
  padding: 0;

  .nav-open & {
    position: fixed;

    @include media('>=nav-width') {
      position: static;
    }
  }
}

ul > li::marker {
  color: color($list-marker-color);
}

h1 {
  @include font-size('h1')
}

p {
  margin-bottom: 1.804rem;
}

a {
  color: color($link-color);
}

a:visited {
  color: color($link-color-visited);
}

a:hover {
  color: color($link-color-hover);
  text-decoration: none;
}

/* Multiple properties with same values */
h1,
h2,
h3,
h4,
h5 {
  color: color($base-font-color);
  font-family: font($heading-font);
  font-weight: weight($heading-font-weight);
  line-height: line-height($heading-line-height);
  margin: 2.75rem 0 1.05rem;
}

h1 {
  @include font-size('h1');
  margin-top: 0;
}

h2 {
  @include font-size('h2');
}

h3 {
  @include font-size('h3');
}

h4 {
  @include font-size('h4');
}

h5 {
  @include font-size('h5');
}

h6 {
  @include font-size('h6');
}

small {
  @include font-size('small');
}

address {
  margin-bottom: 1.804rem;
  
  div {
    @include font-size("small");
  }
}

*[hidden] {
  display: none !important;
}
