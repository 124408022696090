.tight-grid {
  display: grid;
  grid-column-gap: units($grid-column-gap);
  grid-template-rows: auto;
  grid-template-columns:
    minmax(units("1"), 1fr)
    minmax(units("1"), 1fr)
    minmax(units("1"), 1fr)
    minmax(units("1"), 1fr)
    minmax(units("1"), 1fr)
    minmax(units("1"), 1fr)
    minmax(units("1"), 6fr);

  @include media('>=tablet-sm') {
  }
}

.tight-grid__artwork {
  grid-column: 1 / 8;

  margin-bottom: units("3");
  text-align: center;

  @include media('>=tablet-sm') {
    grid-column: 1 / 5;
  }

  @include media('>=desktop') {
    grid-column: 2 / 6;
  }

  img {
    width: 100%;
  }

  p {
    @include font-size("small");
    margin-top: units("1");
  }
}

.tight-grid__content {
  grid-column: 1 / 8;

  @include media('>=tablet-sm') {
    grid-column: 5 / 8;
  }

  @include media('>=desktop') {
    grid-column: 6 / 8;
  }
}

// Narrow artwork
.tight-grid--narrow-artwork {

  .tight-grid__artwork {
    @include media('>=desktop') {
    grid-column: 2 / 5;
  }
  }
}