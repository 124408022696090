/* stylelint-disable number-leading-zero */

/*
===========================================
Paths
===========================================
*/
$base-path: '/static' !default;

/*
===========================================
Breakpoints
===========================================
*/
/// @group Design tokens
$breakpoints: (
  'phone': 320px,
  'phone-lg': 480px,
  'tablet-sm': 600px,
  'tablet': 768px,
  'nav-width': 877px,
  'desktop': 1024px,
  'desktop-lg': 1300px,
  'desktop-xl': 1500px
);

/*
===========================================
Grid
===========================================
*/
/// Total columns
/// @group Layout
$grid-columns: 12 !default;


/*
===========================================
Type
Two font sizes given for responsive type settings
in _fluidtype.scss mixin.
===========================================
*/
$fluid_min: 20rem;
$fluid_max: 136.6rem;

// Min font sizes
$base_sm: 1.6rem;
$h1_sm: 4.883rem;
$h2_sm: 3.906rem;
$h3_sm: 3.125rem;
$h4_sm: 2.5rem;
$h5_sm: 2rem;
$h6_sm: 1.6rem;
$small_sm: 1.28rem;

// Max font sizes
$base_lg: 2rem;
$h1_lg: 6.104rem;
$h2_lg: 4.883rem;
$h3_lg: 3.906rem;
$h4_lg: 3.125rem;
$h5_lg: 2.5rem;
$h6_lg: 2rem;
$small_lg: 1.6rem;

/// Font sizes
/// @group Design tokens
$font-sizes: (
  base:  'base',
  h1:    'h1',
  h2:    'h2',
  h3:    'h3',
  h4:    'h4',
  h5:    'h5',
  h6:    'h6',
  small: 'small',
) !default;

/// Font families
/// @group Design tokens
$fonts: (
  "narrow": "'Signika', sans-serif",
  "wide": "'Work Sans', sans-serif",
) !default;

/// Font weights
/// @group Design tokens
$weights: (
  "light": 300,
  "regular": 400,
  "semibold": 600,
  "bold": 700
) !default;

/// Line length tokens to control the reading length of a line
/// @group Design tokens
$measures: (
  "0.5": 30ex,
  "1": 44ex,
  "2": 60ex,
  "3": 64ex,
  "4": 68ex,
  "5": 72ex,
  "6": 88ex,
  "none": none
) !default;

/// Line height tokens
/// @group Design tokens
$line_heights: (
  "1": 1,
  "2": 1.15,
  "3": 1.35,
  "4": 1.5,
  "5": 1.65,
  "6": 1.75
) !default;

/*
===========================================
Colors
===========================================
*/
/// Colors
/// @group Design tokens
$colors: (
  // Gotta-haves
  "white":    #ffffff,
  "black":   #000000,
  "transparent": transparent,

  // Grays
  "gray-14": #2c2c2c,
  "gray-7":  #595959,
  "gray-3":  #888888,
  "gray-2":  #dddddd,
  "gray-1":  #eeeeee,

  // Blues
  "blue-7":  #213782,
  "blue-5":   #2572E4,
  "blue-3":   #4791FF,
  "blue-1":   #e3f4fe,

  // Reds
  "red-5":    #d60033,
  "red-3":    #ff4a4a,
  "red-1":    #ffedea,

  // Greens
  "green-5":  #48840b,
  "green-3":  #6ba40d,
  "green-1":  #e8f598,

  // Yellows
  "yellow":   #ffd600,

  // Skin tones
  "brown-10": #623309,
  "brown-5":  #a34d1d,
  "brown-3":  #c18b38,
  "brown-3a": #d0823c,
  "brown-2":  #f7bd89,
  "brown-1":  #ffd9b7,
) !default;

/*
===========================================
Base unit
===========================================
This grid is based on the 8pt grid which helps us
ensure consistent horizontal and vertical rhythm.
We are using pixels rather than relative units to ensure
consistent spacing in the event that the root font size
is not equal 10.
More info: https://spec.fm/specifics/8-pt-grid
*/

// Base unit
$base-unit: 8 !default;

/// Map for spacing tokens in Rems
/// @group Design tokens
$units:(
  "0":      0,
  "1px":    (1) / 10,
  "0.25":    ($base-unit * .25) / 10,
  "0.5":     ($base-unit * .5) / 10,
  "1":      ($base-unit * 1) / 10,
  "1.5":    ($base-unit * 1.5) / 10,
  "2":      ($base-unit * 2) / 10,
  "2.5":    ($base-unit * 2.5) / 10,
  "3":      ($base-unit * 3) / 10,
  "4":      ($base-unit * 4) / 10,
  "5":      ($base-unit * 5) / 10,
  "6":      ($base-unit * 6) / 10,
  "7":      ($base-unit * 7) / 10,
  "8":      ($base-unit * 8) / 10,
  "9":      ($base-unit * 9) / 10,
  "10":     ($base-unit * 10) / 10,
  "15":     ($base-unit * 15) / 10,
  "20":     ($base-unit * 20) / 10,
  "-1px":    -((1) / 10),
  "-0.25":    -(($base-unit * .25) / 10),
  "-0.5":     -(($base-unit * .5) / 10),
  "-1":      -($base-unit * 1) / 10,
  "-1.5":    -(($base-unit * 1.5) / 10),
  "-2":      -(($base-unit * 2) / 10),
  "-2.5":    -(($base-unit * 2.5) / 10),
  "-3":      -(($base-unit * 3) / 10),
  "-4":      -(($base-unit * 4) / 10),
  "-5":      -(($base-unit * 5) / 10),
  "-6":      -(($base-unit * 6) / 10),
  "-7":      -(($base-unit * 7) / 10),
  "-8":      -(($base-unit * 8) / 10),
  "-9":      -(($base-unit * 9) / 10),
  "-10":     -(($base-unit * 10) / 10),
  "-15":     -(($base-unit * 15) / 10),
  "-20":     -(($base-unit * 20) / 10),
) !default;

/*
===========================================
Borders
===========================================
*/
/// Border radii
/// @group Design tokens
$radii: (
  0: 0,
  xs: 1px,
  sm: 2px,
  md: 4px,
  lg: 8px,
  pill: 99rem,
) !default;

/*
===========================================
Transition speeds
===========================================
*/
/// Durations
/// @group Design tokens
$durations: (
  "1": 100ms,
  "2": 200ms,
  "3": 300ms,
  "4": 400ms,
  "5": 500ms,
) !default;


/*
===========================================
Z-indexes
===========================================
*/
/// Z-Index
/// @group Design tokens
$z-indexes: (
  "-1": -1,
  "sticky-nav": 3000,
  "overlay": 4000,
  "flyout-menu": 5000,
) !default;